import { Link as ScrollLink } from 'react-scroll'
import { useLocation, Outlet } from 'react-router-dom'
// material
import { Box, Link, Container, Typography, Stack } from '@material-ui/core'
// components
import Logo from '../../components/Logo'
//
import MainNavbar from './MainNavbar'
import MainFooter from './MainFooter'
import SeemaLogo from '../../images/seema-h-logo-modified.png'
import SeemaFooter from './SeemaFooter'

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation()
  const isHome = pathname === '/'
  const Career = pathname === '/media-profile'

  return (
    <>
      {!isHome && <MainNavbar />}
      <div>
        <Outlet />
      </div>

      {Career ? '' : isHome ? '' : <SeemaFooter />}

      {/* <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default'
          }}
        >
          <Container maxWidth="lg">
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mb: 1, mx: 'auto', cursor: 'pointer' }} />
              <Box component={'img'} src={SeemaLogo} sx={{ mb: 2, mx: 'auto', cursor: 'pointer', }} className='logo-responsive' alt='Logo' />
            </ScrollLink>
            <Stack direction={'row'} justifyContent={'space-around'} sx={{ml:2.7}}>
              <Typography variant="caption" component="p">
                © All rights reserved
                <br /> made by &nbsp;
                <Link target='_blank' href="https://skyniche.com/">skyniche Production</Link>
              </Typography>
            </Stack>

            
          </Container>
        </Box> */}
    </>
  )
}
