import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden, MIconButton } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import SeemaLogo from '../../images/seema-h-logo-modified.png';
import { Close } from '@material-ui/icons';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const navigate = useNavigate();
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isCareer = pathname === '/media-profile';
  const isPhotoes = pathname === '/about';
  const isPhotoes1 = pathname === '/say-hello';
  const isHome = pathname === '/home';

  return (
    <AppBar
      color="default"
      sx={{
        backgroundColor: (theme) =>
          isCareer ? 'transparent' : isPhotoes ? 'transparent' : isPhotoes1 ? 'transparent' : theme.palette.background.default,
        boxShadow: 0
      }}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: isCareer ? 'transparent' :  'background.default',
            height: APP_BAR_MOBILE
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <img src={SeemaLogo} className='logo-responsive' alt='Logo' />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />

          {!isCareer ? (
            <MHidden width="mdDown">
              <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
            </MHidden>
          ) : (
            <MIconButton onClick={() => navigate('/home')}>
              <Close sx={{ color: 'white' }} />
            </MIconButton>
          )}

          {!isCareer ? (
            <MHidden width="mdUp">
              <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
            </MHidden>
          ) : ''}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
