import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, Stack, useMediaQuery, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Facebook, Instagram, Twitter, YouTube } from '@material-ui/icons';
import FooterLogo from '../../images/skyniche-logo-footer.png';
import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'FaceBook', icon: 'icomoon-free--facebook', link: 'https://www.facebook.com/officialseemajaswal/' },
  { name: 'Twitter', icon: 'bi--twitter-x', link: 'https://x.com/seemajaswal' },
  { name: 'Youtube', icon: 'bi--youtube', link: 'https://www.youtube.com/channel/UCxTBcCODs9mJp0KsxzCZtQg' },
  { name: 'Instagram', icon: 'bi--instagram', link: 'https://www.instagram.com/seemajaswal/' },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

const items = [
  { label: 'Terms and Conditions', route: '/terms-and-conditions' },
  { label: 'Privacy Policy', route: '/privacy-policy' },
  { label: 'Cookie Policy', route: '/cookie-policy' },
];

export default function SeemaFooter() {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const location = useLocation(); 
  const [activeRoute, setActiveRoute] = useState(location.pathname); 

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 2, pb: 2 }}>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ textAlign: 'center' }}>
          <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>
            <Stack
              spacing={1.5}
              direction={'row'}
              justifyContent={isMdUp ? 'flex-start' : 'center'}
              alignItems={isMdUp ? 'flex-start' : 'center'}
            >
              {SOCIALS.map((social) => (
                <Link
                  href={social.link}
                  target="_blank"
                  key={social.name}
                  sx={{
                    p: 1,
                    cursor: 'pointer',
                    color: 'rgba(255,255,255,.4)',
                    '&:hover': {
                      opacity: 1,
                      color: '#EC1A49',
                    },
                  }}
                >
                  <Stack className={social.icon} sx={{ fontSize: '1.5rem' }} />
                </Link>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography
              variant="body2"
              sx={{
                textTransform: 'uppercase',
                fontSize: '12px',
                fontWeight: 600,
                fontFamily: '"proxima-nova-condensed","Helvetica",arial,sans-serif',
                opacity: 0.4,
                mb: isMdUp ? 0 : 2,
                mt: isMdUp ? 0 : 1,
              }}
            >
              © {new Date().getFullYear()} Seema Jaswal, All Rights Reserved
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} sx={{ textAlign: isMdUp ? 'right' : 'center', mb: isMdUp ? 0 : 1 }}>
            <Stack
              direction={'row'}
              sx={{ opacity: 0.4, cursor: 'pointer', '&:hover': { opacity: 1 } }}
              justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end' }}
            >
              <Box
                component={'img'}
                src={FooterLogo}
                sx={{ width: 25, height: 25, objectFit: 'contain', mr: 1, mt: -0.3 }}
                alt="Footer Logo"
              />
              <Link
                href="https://skyniche.com"
                target="_blank"
                color="inherit"
                underline="none"
                variant="body2"
                sx={{
                  textDecoration: 'none',
                  fontSize: '14px',
                  fontWeight: 500,
                  fontFamily: '"proxima-nova-condensed","Helvetica",arial,sans-serif',
                  '&:hover': {
                    opacity: 0.4,
                  },
                }}
              >
                A skyniche Production
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{ borderColor: '#232323' }} />
      <Grid
        container
        justifyContent={{ xs: 'center', md: 'center' }}
        sx={{ textAlign: { xs: 'center', md: 'center' }, gap: { xs: 0, sm: 2, md: 2 }, mt: 0.3 }}
      >
        {items.map((item, index) => (
          <>
            <Link
              key={index}
              component="p"
              variant="body2"
              underline="none"
              target='_blank'
              onClick={() => handleNavigate(item.route)}
              sx={{
                mt: 3,
                pb: { xs: 3, sm: 3, md: 3 },
                pl: { xs: '8px', sm: 0, md: 0 },
                pr: { xs: '8px', sm: 0, md: 0 },
                textTransform: 'uppercase',
                fontSize: { xs: '11px', sm: '12px', md: '12px' },
                fontWeight: 600,
                fontFamily: '"proxima-nova-condensed","Helvetica",arial,sans-serif',
                opacity: activeRoute === item.route ? 1 : 0.4,
                color: 'common.white',
                cursor: 'pointer',
                textAlign: { xs: 'center', md: 'center' },
                '&:hover': {
                  opacity: 1,
                },
              }}
            >
              {item.label}
            </Link>
            {index < items.length - 1 && (
              <Typography
                sx={{
                  mt: 3,
                  pb: 3,
                  fontSize: 13,
                  color: 'common.white',
                  textAlign: { xs: 'center', md: 'center' },
                }}
              >
                {' | '}
              </Typography>
            )}
          </>
        ))}
      </Grid>
    </RootStyle>
  );
}
