import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, List, Drawer, Link, ListItem, Typography, Grid, Stack } from '@material-ui/core';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import { MIconButton } from '../../components/@material-extend';
import menuConfig from './MenuConfig';
import { keyframes } from '@emotion/react';

// ----------------------------------------------------------------------

const ICON_SIZE = 22;
const ITEM_SIZE = 48;
const PADDING = 2.5;

const SOCIALS = [
  { name: 'FaceBook', icon: 'icomoon-free--facebook', link: 'https://www.facebook.com/officialseemajaswal/' },
  { name: 'Twitter', icon: 'bi--twitter-x', link: 'https://x.com/seemajaswal' },
  { name: 'Youtube', icon: 'bi--youtube', link: 'https://www.youtube.com/channel/UCxTBcCODs9mJp0KsxzCZtQg' },
  { name: 'Instagram', icon: 'bi--instagram', link: 'https://www.instagram.com/seemajaswal/' },
];

// Keyframe animations for sliding effects
const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInFromBottom = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Styled components
const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  height: ITEM_SIZE,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(PADDING),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  animation: `${slideInFromLeft} 0.5s ease-out`,
  [theme.breakpoints.down('md')]: {
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(2),
    },
  },
  [theme.breakpoints.down('sm')]: {
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(0.5),
    },
  },

}));

const LinkStyle = styled(RouterLink)(({ theme, active }) => ({
  color: active ? '#FFFFFF' : 'rgba(255, 255, 255, 0.4)',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  fontWeight: 700,
  fontSize: '18px',
  textDecoration: 'none',
  position: 'relative',
  transition: 'color 0.4s ease-in-out',
  fontFamily: '"proxima-nova-condensed","Helvetica",arial,sans-serif',
  '&:hover, &:focus': {
    color: '#FFFFFF',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '1px',
    backgroundColor: '#ec1a49',
    top: '100%',
    left: '0',
    transform: active ? 'scaleX(1)' : 'scaleX(0)',
    transition: 'transform 400ms cubic-bezier(0.19, 1, 0.22, 1)',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '23px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}));

const FooterTextStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'center',
  animation: `${slideInFromBottom} 0.5s ease-out`,
  marginTop: theme.spacing(2),
}));

// ----------------------------------------------------------------------

MenuMobileItem.propTypes = {
  item: PropTypes.object,
  isActive: PropTypes.bool,
};

function MenuMobileItem({ item, isActive }) {
  const { title, path } = item;

  return (
    <ListItemStyle key={title}>
      <LinkStyle to={path} active={isActive}>
        {title}
      </LinkStyle>
    </ListItemStyle>
  );
}

MenuMobile.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
};

export default function MenuMobile({ isOffset, isHome }) {
  const { pathname } = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (mobileOpen) {
      handleDrawerClose();
    }
  }, [pathname]);

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  return (
    <>
      {!mobileOpen ? (
        <MIconButton
          onClick={handleDrawerOpen}
          sx={{
            ml: 1,
            ...(isHome && { color: 'text.primary' }),
            ...(isOffset && { color: 'text.primary' }),
          }}
        >
          <Icon icon={menu2Fill} />
        </MIconButton>
      ) : (
        <MIconButton
          onClick={handleDrawerClose}
          sx={{
            ml: 1,
            ...(isHome && { color: 'text.primary' }),
            ...(isOffset && { color: 'text.primary' }),
          }}
        >
          <Icon icon={closeFill} />
        </MIconButton>
      )}
      <Drawer
        open={mobileOpen}
        onClose={handleDrawerClose}
        className='css-1pvsm7j-MuiModal-root-MuiDrawer-root1'
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            bgcolor: 'background.default',
            position: 'relative',
          },
        }}
      >
        <Scrollbar sx={{ flexGrow: 1, paddingLeft: '16px', paddingTop: {xs:'100px',sm:'150px'} }}>
        {/* <Scrollbar sx={{ flexGrow: 1, paddingLeft: '16px', paddingTop: {xs:'100px',sm:'25%'} }}> */}
          <List disablePadding sx={{ width: '100%', textAlign: 'left' }}>
            {menuConfig.map((link) => (
              <MenuMobileItem key={link.title} item={link} isActive={pathname === link.path} />
            ))}
          </List>
        </Scrollbar>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px 16px',
            animation: `${slideInFromBottom} 0.5s ease-out`,
            mt: 'auto',
          }}
        >
          <Link to="/privacy-policy"
            sx={{
              color: 'rgba(255, 255, 255, 255)',
              textTransform: 'uppercase',
              letterSpacing: '2px',
              fontWeight: 700,
              fontSize: '13px',
              textDecoration: 'none',
              position: 'relative',
              whiteSpace: 'nowrap',
              mt: 1
            }}
            component={RouterLink}>
            Privacy Policy
          </Link>
          <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>
            <Stack
              spacing={1}
              direction={'row'}
              justifyContent={'flex-end'}
              alignItems={'flex-end'}
            >
              {SOCIALS.map((social) => (
                <Link
                  href={social.link}
                  target="_blank" key={social.name} sx={{
                    p: 1, cursor: 'pointer', color: 'rgba(255,255,255,.7)', '&:hover': {
                      opacity: 1,
                      color: '#EC1A49'
                    },
                  }}>
                  <Stack className={social.icon} sx={{ fontSize: '1.2rem' }} />
                </Link>
              ))}
            </Stack>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
}
